<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary bg-soft">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Đăng ký</h5>
                    <p>Tạo tài khoản để làm việc.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img src="/stoke/assets/images/profile-img.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <a href="index.html">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        src="/stoke/assets/images/logo.svg"
                        alt=""
                        class="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </a>
              </div>
              <div class="p-2">
                <form @submit.prevent="register()" class="needs-validation" novalidate>
                  <div class="mb-3">
                    <label for="username" class="form-label">Họ và Tên của bạn</label>
                    <input
                      v-model="name"
                      type="text"
                      class="form-control"
                      id="username"
                      placeholder="Nhập tên của bạn"
                      required
                    />
                    <div class="invalid-feedback">Please Enter firstname</div>
                  </div>
                  <div class="mb-3">
                    <label for="useremail" class="form-label">Email</label>
                    <input
                      v-model="email"
                      type="email"
                      class="form-control"
                      id="useremail"
                      placeholder="Nhập email của bạn"
                      required
                    />
                    <div class="invalid-feedback">Please Enter Email</div>
                  </div>

                  <div class="mb-3">
                    <label for="userpassword" class="form-label">Mật khẩu</label>
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      id="userpassword"
                      placeholder="Nhập mật khẩu của bạn"
                      required
                    />
                    <div class="invalid-feedback">Please Enter Password</div>
                  </div>

                  <div class="mb-3">
                    <label for="userpasswordconfirm" class="form-label">Xác nhận mật khẩu</label>
                    <input
                      v-model="password_confirm"
                      type="password"
                      class="form-control"
                      id="userpasswordconfirm"
                      placeholder="Nhập lại mật khẩu của bạn"
                      required
                    />
                    <div class="invalid-feedback">Please Enter passwordconfirm</div>
                  </div>

                  <div class="mb-3">
                    <label for="telegramId" class="form-label">Telegram ID</label>
                    <a class="float-end" target="_blank" href="https://youtu.be/S94TS1g2FqI"
                      >Video hướng dẫn</a
                    >
                    <input
                      v-model="telegram_id"
                      type="text"
                      class="form-control"
                      id="telegramId"
                      placeholder="Nhập telegram Id để nhận thông báo"
                      required
                    />
                    <small
                      >Hướng dẫn:
                      <a class="fw-bold" target="_blank" href="https://t.me/metech_software_bot"
                        >Click vào đây</a
                      >
                      để chat với bot, sau đó gõ <span class="fw-bold text-danger">/id</span> để lấy
                      id của bạn</small
                    >
                    <div class="invalid-feedback">Please Enter telegramId</div>
                  </div>

                  <div class="mt-4 d-grid">
                    <button class="btn btn-primary waves-effect waves-light" type="submit">
                      Đăng Ký
                    </button>
                  </div>

                  <div class="mt-4 text-center">
                    <h5 class="font-size-14 mb-3">Hoặc đăng ký với</h5>

                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-primary text-white border-primary"
                        >
                          <i class="mdi mdi-facebook"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-info text-white border-info"
                        >
                          <i class="mdi mdi-twitter"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a
                          href="javascript::void()"
                          class="social-list-item bg-danger text-white border-danger"
                        >
                          <i class="mdi mdi-google"></i>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="mt-4 text-center">
                    <p class="mb-0">
                      Bằng cách đăng ký bạn đã đồng ý với
                      <a href="#" class="text-primary">Quy định của Metech</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <div>
              <p>
                Đã có tài khoản ?
                <router-link to="/login" class="fw-medium text-primary"> Đăng nhập</router-link>
              </p>
              <p>© 2021 Metech. Dev with <i class="mdi mdi-heart text-danger"></i> by Dungqb</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import authApi from "@/api/auth.js";
export default {
  name: "register",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirm: "",
      telegram_id: "",
    };
  },
  methods: {
    async register() {
      if (this.password != this.password_confirm) {
        this.$swal("Lỗi", "Mật khẩu và xác nhận mật khẩu không khớp nhau", "error");
        return;
      }
      if (this.telegram_id.length < 6) {
        this.$swal(
          "Lỗi",
          "Nhập telegram id của bạn để nhận thông báo, xem video hướng dẫn để biết cách lấy id",
          "error"
        );
        return;
      }
      const { email, password, name, telegram_id } = this;
      let data = await authApi.register({
        email,
        password,
        name,
        telegram_id,
      });
      console.log(data);
      if (!data.error) {
        this.$swal("Thành công", "Đăng ký tài khoản thành công", "success");
      } else {
        this.$swal("Lỗi", "Đăng ký tài khoản thất bại", "error");
      }
    },
  },
};
</script>
